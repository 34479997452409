import { useEffect, useState } from "react";
import { SelectInput, Options } from "../SelectInput";
import { Alert, AlertTypes } from "../Alert";
import { Button, ButtonTypes } from "../Button";
import TextInput, { InputTypes } from "../TextInput";
import { getAllCorporations } from "../../helpers/corporation";
import { generateTenancyReport } from "../../api/tools";
import { API_ENV, HOCOCO_JOB_SECRETS } from "../../helpers/request";
import { Checkbox } from "../Checkbox";

export default function GenerateTenancyReport() {
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [corporationIds, setCorporationIds] = useState([] as Options[]);
    const [allCorporations, setAllCorporations] = useState([]);
    const [errorResponse, setErrorResponse] = useState('');
    const [successReponse, setSuccessResponse] = useState('');
    const [autoFix, setAutoFix] = useState(false);

    useEffect(() => {
        getAllCorporations().then(corporations => {
            if (corporations) {
                setAllCorporations(corporations as any);
            }
        })
    }, [])

    async function submit(e: Event) {
        e.preventDefault();
        resetState();
        try {
            const requestCorporationIds: number[] = corporationIds.map((corporation: Options) => corporation.value) as number[];

            await generateTenancyReport({
                secret: HOCOCO_JOB_SECRETS[API_ENV],
                fromDate,
                toDate,
                corporationIds: requestCorporationIds,
                autoFixProblems: autoFix
            });
            setSuccessResponse('The tenancy report was succesfully triggered. You should receive a message on Slack with the status after few minutes.');
        } catch (e) {
            setErrorResponse('There was an error while trying to schedule the tenancy report.')
        }
    }

    function resetState() {
        setErrorResponse('');
        setSuccessResponse('');
    }

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className="card mb-4 full-width">
                        <h5 className="card-header">Generate tenancy period quality report</h5>
                        <div className="card-body">
                            <div className="row g-3">
                                <div className="col-md-4">
                                    <TextInput
                                        onValue={(value: string) => setFromDate(value)}
                                        label="From"
                                        description="Start date of the report"
                                        type={InputTypes.DATE_TIME}
                                        htmlOptions={
                                            {
                                                pattern: "\\d{4}-\\d{2}-\\d{2}"
                                            }
                                        }
                                    />
                                </div>
                                <div className="col-md-4">
                                    <TextInput
                                        onValue={(value: string) => setToDate(value)}
                                        label="To"
                                        description="End date of the report"
                                        type={InputTypes.DATE_TIME}
                                        htmlOptions={
                                            {
                                                pattern: "\\d{4}-\\d{2}-\\d{2}"
                                            }
                                        }
                                    />
                                </div>
                                <div className="col-md-4">
                                    <SelectInput
                                        label="Corporations"
                                        options={
                                            allCorporations
                                        }
                                        placeholder={
                                            {
                                                label: 'Specific corporation(s)'
                                            }
                                        }
                                        value={corporationIds}
                                        multiSelect={true}
                                        description="Only select if you don't want the report to be generated for specific corporations"
                                        onValue={(values: any[]) => setCorporationIds(values)}
                                    />
                                </div>
                                <div className="col-md-12">
                                    <Checkbox
                                        label="Fix all auto-fixable problems"
                                        checked={autoFix}
                                        onChange={(value: boolean, e: Event) => setAutoFix(value)}
                                    />
                                </div>
                            </div>
                            <div className="pt-4">
                                <Button type={ButtonTypes.PRIMARY} text="Generate report" onPress={(e: Event) => submit(e)} />
                            </div>
                            {errorResponse && (
                                <div className="pt-4">
                                    <Alert type={AlertTypes.DANGER} text={errorResponse} />
                                </div>
                            )}
                            {successReponse && (
                                <div className="pt-4">
                                    <Alert type={AlertTypes.SUCCESS} text={successReponse} />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}