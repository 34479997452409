import { useState } from "react";
import { Button, ButtonTypes } from "../Button";
import { Alert, AlertTypes } from "../Alert";
import { databaseBackup } from "../../api/tools";

export default function BackupDatabase() {
    const [errorResponse, setErrorResponse] = useState('');
    const [successReponse, setSuccessResponse] = useState('');

    async function submit(e: Event) {
        e.preventDefault();
        resetState();
        const response = await databaseBackup();
        if (response?.exception) {
            setErrorResponse(response?.message);
        } else {
            setSuccessResponse(response?.message);
        }
    }

    function resetState() {
        setErrorResponse('');
        setSuccessResponse('');
    }

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className="card mb-4 full-width">
                        <h5 className="card-header">Backup database</h5>
                        <div className="card-body">
                            <div className="pt-4">
                                <Button type={ButtonTypes.PRIMARY} text="Request backup" onPress={(e: Event) => submit(e)} />
                            </div>
                            {errorResponse && (
                                <div className="pt-4">
                                    <Alert type={AlertTypes.DANGER} text={errorResponse} />
                                </div>
                            )}
                            {successReponse && (
                                <div className="pt-4">
                                    <Alert type={AlertTypes.SUCCESS} text={successReponse} />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}