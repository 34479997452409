import { useState } from "react";
import TextInput, { InputTypes } from "../TextInput";
import { Button, ButtonTypes } from "../Button";
import { Alert, AlertTypes } from "../Alert";
import { Modal } from "../Modal";
import { RevertMoveOutRequestOverviewResponse, revertMoveOut, revertMoveOutOverview } from "../../api/tools";
import { Table } from "../Table";

export default function RevertMoveOutForm() {
    const [tenancyPeriodTenantId, setTenancyPeriodTenantId] = useState(0);
    const [errorResponse, setErrorResponse] = useState('');
    const [successReponse, setSuccessResponse] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [overview, setOverview] = useState({} as RevertMoveOutRequestOverviewResponse | null);

    function isFormValid() {
        return tenancyPeriodTenantId;
    }

    async function handleSubmit(e: Event) {
        e.preventDefault();
        const overview = await revertMoveOutOverview({ tenancyPeriodTenantId });
        setOverview(overview);
        setShowModal(true);
    }

    async function submit(e: Event) {
        e.preventDefault();
        resetState();
        const response = await revertMoveOut({ tenancyPeriodTenantId });
        if (response?.exception) {
            setErrorResponse(response?.message);
        } else {
            setSuccessResponse(response?.message);
        }
    }

    function resetState() {
        setErrorResponse('');
        setSuccessResponse('');
        setOverview(null);
        setShowModal(false);
    }

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className="card mb-4 full-width">
                        <h5 className="card-header">Revert tenant move-out</h5>
                        <div className="card-body">
                            <div className="row g-3">
                                <div className="col-md-6">
                                    <TextInput
                                        onValue={(value: number) => setTenancyPeriodTenantId(value)}
                                        label="Tenancy period tenant"
                                        placeholder="8067"
                                        description="ID of the tenancy period tenant"
                                        type={InputTypes.NUMBER}
                                    />
                                </div>
                            </div>
                            <div className="pt-4">
                                <Button type={ButtonTypes.PRIMARY} text="Revert move-out" className={!isFormValid() ? 'disabled' : ''} onPress={(e: Event) => handleSubmit(e)} />
                            </div>
                            {errorResponse && (
                                <div className="pt-4">
                                    <Alert type={AlertTypes.DANGER} text={errorResponse} />
                                </div>
                            )}
                            {successReponse && (
                                <div className="pt-4">
                                    <Alert type={AlertTypes.SUCCESS} text={successReponse} />
                                </div>
                            )}
                            {showModal && (
                                <Modal
                                    title="Details about the action"
                                    onConfirm={(e: Event) => submit(e)}
                                    onCancel={(e: Event) => setShowModal(false)}
                                >
                                    {overview?.tenant && overview.unit && (
                                        <>
                                            <Table
                                                cols={[
                                                    { name: 'User email' },
                                                    { name: 'User name' },
                                                    { name: 'Property ID' },
                                                    { name: 'Property title' }
                                                ]}
                                                rows={[
                                                    {
                                                        cells: [
                                                            { content: `${overview?.tenant?.email}` },
                                                            { content: `${overview.tenant?.person?.first_name} ${overview.tenant?.person?.family_name}` },
                                                            { content: `${overview.unit?.id}` },
                                                            { content: `${overview.unit?.title_number}` }
                                                        ]
                                                    }
                                                ]}
                                            />
                                        </>
                                    )}
                                    <p className="mt-4 fw-bold">Are you sure you want to proceed?</p>
                                </Modal>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}