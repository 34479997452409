import { clearStorage, removeItem, setItem } from "./storage";

export const USER_INFO_KEY = 'userInfo';

export async function setUserInfo(info: string): Promise<string> {
    return await setItem(USER_INFO_KEY, info);
}

export async function deleteUserTokenInfo() {
    return await removeItem(USER_INFO_KEY);
}

export async function logout() {
    await clearStorage();
    return window.location.reload();
}