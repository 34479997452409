import { ReactNode } from "react"

interface Prop {
    children: ReactNode,
    title?: string,
    cancelButtonText?: string,
    confirmButtonText?: string,
    onConfirm : Function,
    onCancel: Function
}

export function Modal({ children, title, cancelButtonText = 'Cancel', confirmButtonText = 'Confirm', onConfirm, onCancel }: Prop) {
    return (
        <div className="modal fade show" style={{ display: 'block' }} id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    {title && (
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">{title}</h5>
                            <button onClick={(e) => onCancel(e)} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                    )}
                    <div className="modal-body">
                        {children}
                    </div>
                    <div className="modal-footer">
                        <button type="button" onClick={(e) => onCancel(e)} className="btn btn-secondary" data-bs-dismiss="modal">{cancelButtonText}</button>
                        <button type="button" onClick={(e) => onConfirm(e)} className="btn btn-primary">{confirmButtonText}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}