import { ReactNode, MouseEvent } from "react";
import { Link } from "react-router-dom";
import { logout } from "../../helpers/user";

interface Props {
    children?: ReactNode
}

export default function AuthWrapper({ children }: Props) {

    async function handleLogout(e: MouseEvent) {
        e.preventDefault();
        return await logout();
    }

    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <aside id="layout-menu" className="layout-menu menu-vertical menu bg-menu-theme" data-bg-class="bg-menu-theme" style={{ touchAction: 'none', userSelect: 'none', WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)' }}>
                    <div className="app-brand" style={{ marginTop: 20, marginBottom: 10 }}>
                        <a href="/" className="app-brand-link" style={{ justifyContent: 'center' }}>
                            <img src="https://content.hococo.io/hubfs/2022%20Webflow_Website%20images/Text%20logo_green_new_Notion_.png" alt="Hococo Logo" style={{ width: '50%' }} />
                        </a>
                    </div>
                    <div className="menu-inner-shadow" />
                    <ul className="menu-inner py-4 ps ps--active-y">
                        <li className="menu-item active">
                            <Link to="/" className="menu-link">
                                <i className="menu-icon tf-icons bx bx-home" />
                                <div>Dashboard</div>
                            </Link>
                        </li>
                        <li className="menu-header small text-uppercase">
                            <span className="menu-header-text">Actions</span>
                        </li>
                        <li className="menu-item">
                            <a className="menu-link" href="#!" onClick={(e) => handleLogout(e)}>
                                <i className="bx bx-power-off me-2"></i>
                                <span className="align-middle">Log Out</span>
                            </a>
                        </li>
                    </ul>
                </aside>
                <div className="layout-page">
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}