interface Prop {
    text?: string,
    className?: string,
    onPress: Function,
    type?: ButtonTypes,
    htmlType?: HtmlButtonTypes
}

export enum ButtonTypes {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    SUCCESS = 'success',
    DANGER = 'danger',
    WARNING = 'warning',
    INFO = 'info',
    DARK = 'dark'
}

export enum HtmlButtonTypes {
    SUBMIT = 'submit',
    BUTTON = 'button'
}

export function Button({ text = 'Submit', onPress, type = ButtonTypes.PRIMARY, className = '', htmlType = HtmlButtonTypes.BUTTON  }: Prop) {
    return (
        <button type={htmlType} className={`btn me-sm-3 me-1 btn-${type} ${className}`} onClick={(e) => onPress(e)}>{ text }</button>
    )
}