import { ExcelMapper, MapperActions } from '../ExcelMapper';
import { useEffect, useState } from 'react';
import { getAllCorporations } from '../../helpers/corporation';
import { SelectInput } from '../SelectInput';
import { getCorporationProjects } from '../../api/tools';
import { buildCorporationProjectsOptions } from '../../helpers/project';
import ReactFlagsSelect from 'react-flags-select';

interface Prop {
    apiFields: string[]
}

export default function ImportKnowledgeBase({ apiFields }: Prop) {

    const [projectId, setProjectId] = useState(0);
    const [corporationId, setCorporationId] = useState(0);
    const [allCorporations, setAllCorporations] = useState([]);
    const [projects, setProjects] = useState([]);
    const [countryCode, setCountryCode] = useState('DK');
    const [languageKey, setLanguageKey] = useState('da');

    useEffect(() => {
        getAllCorporations().then(corporations => {
            if (corporations) {
                setAllCorporations(corporations as any);
            }
        })
    }, [])

    useEffect(() => {
        if (corporationId > 0) {
            setProjects([]);
            setProjectId(0);
            getCorporationProjects({ corporationId }).then((projects: any[]) => {
                if (projects?.length) {
                    setProjects(
                        buildCorporationProjectsOptions(projects)
                    );
                }
            });
        }
    }, [corporationId])

    const handleLanguageSelect = (countryCode: string) => {
        setCountryCode(countryCode);
        switch (countryCode) {
            case 'DK':
                setLanguageKey('da');
                break;
            case 'SE':
                setLanguageKey('sv');
                break;
            case 'ES':
                setLanguageKey('es');
                break;
            default:
                setLanguageKey('da');
                break;
        }
    }

    return (
        <>
            <div className='row'>
                <div className='col-md-12'>
                    <div className="card p-4 mb-4 full-width">
                        <h5 className="card-header">Import knowledge base</h5>

                        <div className='card-body'>
                            <div className='row g-3'>
                                <div className="col-md-4">
                                    <SelectInput
                                        label="Corporation"
                                        options={
                                            allCorporations
                                        }
                                        placeholder={
                                            {
                                                value: '',
                                                label: 'Select corporation'
                                            }
                                        }
                                        value={corporationId}
                                        onValue={(option: any) => setCorporationId(option?.value)}
                                    />
                                </div>
                                <div className='mb-4 col-md-4'>
                                    {
                                        corporationId &&
                                            corporationId > 0 &&
                                            projects.length > 0 ? (
                                            <SelectInput
                                                label="Projects"
                                                options={
                                                    projects
                                                }
                                                placeholder={
                                                    {
                                                        value: '',
                                                        label: 'Select project'
                                                    }
                                                }
                                                value={projectId}
                                                onValue={(option: any) => setProjectId(option?.value)}
                                            />

                                        ) : corporationId ? <p>Loading projects...</p> : null}
                                </div>
                                <div className='mb-4 col-md-4'>
                                    {
                                        corporationId && projectId && projectId > 0 ? (
                                            <>
                                                <label htmlFor="defaultFormControlSelect" className="form-label">Localized language</label>
                                                <ReactFlagsSelect
                                                    countries={["DK", "SE", "ES"]}
                                                    customLabels={{ DK: "Danish", SE: "Swedish", ES: "Spanish" }}
                                                    onSelect={(code: string) => handleLanguageSelect(code)}
                                                    selected={countryCode}
                                                    selectButtonClassName="flags-select"
                                                />
                                            </>
                                        ) : null
                                    }
                                </div>
                                {projectId && projectId > 0 ? (
                                    <ExcelMapper 
                                        apiFields={apiFields} 
                                        resourceId={projectId} 
                                        action={MapperActions.IMPORT_KNOWLEDGE_BASE}
                                        languageKey={languageKey}
                                    />
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}