import Select, { ActionMeta, MultiValue, SingleValue } from 'react-select'

interface Prop {
    options: Options[],
    placeholder: Options,
    label?: string,
    description?: string,
    isClearable?: boolean
    onValue: Function,
    value: string | number | Options[],
    multiSelect?: boolean
}

export interface Options {
    value?: string | number,
    label: string,
    disabled?: boolean
}

function onValueChange(
    option: SingleValue<Options> | MultiValue<Options>,
    actionMeta: ActionMeta<Options>,
    onValue: Function
) {
    onValue(option);
}

export function SelectInput(
    {
        options, placeholder = { value: '', label: 'Select', disabled: true },
        label, description, isClearable = false, onValue, multiSelect = false
    }: Prop) {
    return (
        <>
            <label htmlFor="defaultFormControlSelect" className="form-label">{label}</label>
            <Select
                options={options}
                defaultValue={!multiSelect ? placeholder : null}
                placeholder={multiSelect ? placeholder.label : null}
                onChange={(option, actionMeta) => onValueChange(option, actionMeta, onValue)}
                isSearchable={true}
                isClearable={isClearable}
                isMulti={multiSelect}
            />
            <div className="form-text">{description}</div>
        </>
    )
}