import { useState, useEffect } from 'react';
import { SelectInput } from "../SelectInput";
import { Alert, AlertTypes } from '../Alert';
import { Button, ButtonTypes } from "../Button";
import { Modal } from "../Modal";
import { Checkbox } from "../Checkbox";
import { sendWelcomeEmails, SendWelcomeEmailsResponse } from "../../api/tools";
import { Spinner, SpinnerColor } from '../Spinner';
import { getAllCorporations } from '../../helpers/corporation';
import { API_ENV, HOCOCO_JOB_SECRETS } from '../../helpers/request';
import { delay } from '../../helpers/misc';

export default function SendWelcomeEmail() {

    const [corporationId, setCorporationId] = useState(0);
    const [allCorporations, setAllCorporations] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [errorResponse, setErrorResponse] = useState('');
    const [successResponse, setSuccessResponse] = useState({} as SendWelcomeEmailsResponse);
    const [retry, setRetry] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    async function handleSubmit(e: Event) {
        e.preventDefault();
        setShowModal(true);
    }


    useEffect(() => {
        getAllCorporations().then(corporations => {
            if (corporations) {
                setAllCorporations(corporations as any);
            }
        })
    }, [])

    async function submit(e: Event) {
        e.preventDefault();
        resetState();
        setIsLoading(true);
        try {
            let response: SendWelcomeEmailsResponse;
            const secret = HOCOCO_JOB_SECRETS[API_ENV];
            response = await sendWelcomeEmails({ corporationId, secret});

            if (response?.tenantsMissing) {
                setSuccessResponse(response);
                setIsLoading(false);
                if (retry) {
                    await delay(120000);
                    submit(e);
                }
            } else {
                resetState();
            }
        } catch (err) {
            setErrorResponse(err as string);
            setIsLoading(false);
        }
    }

    function resetState() {
        setSuccessResponse({} as SendWelcomeEmailsResponse);
        setErrorResponse('');
        setShowModal(false);
        setIsLoading(false);
    }


    return (
        <>
            <div className="card p-4 mb-4 full-width">
                <h5 className="card-header">Send welcome emails</h5>
                <div className="card-body">
                    <div className="row g-3">
                        <div className="col-md-6">
                            <SelectInput
                                label="Corporation"
                                options={
                                    allCorporations
                                }
                                placeholder={
                                    {
                                        value: '',
                                        label: 'Select corporation'
                                    }
                                }
                                value={corporationId}
                                onValue={(option: any) => setCorporationId(option.value)}
                            />

                            <Checkbox
                                label="Retry until completed"
                                checked={retry}
                                onChange={(value: boolean, e: Event) => setRetry(value)}
                            />
                        </div>
                        <div className="pt-4">
                            <Button type={ButtonTypes.PRIMARY} text="Send welcome emails" className={!corporationId ? 'disabled' : ''} onPress={(e: Event) => handleSubmit(e)} />
                        </div>
                        {showModal && (
                            <Modal
                                title="Details about the action"
                                onConfirm={(e: Event) => submit(e)}
                                onCancel={(e: Event) => setShowModal(false)}
                            >
                                <div>
                                    Are you sure you want to continue?
                                </div>
                            </Modal>
                        )}
                        {errorResponse && (
                            <div className="pt-4">
                                <Alert type={AlertTypes.DANGER} text={errorResponse} />
                            </div>
                        )}
                        {isLoading && (
                            <Spinner color={SpinnerColor.PRIMARY} />
                        )}
                        {successResponse?.tenantsMissing && (
                            <div className="pt-4">
                                <Alert type={AlertTypes.SUCCESS} text={`Welcome emails sent. There are aproximately ${successResponse?.tenantsMissing} missing. ${retry ? 'Retrying in 2 minutes.' : ''}`} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}