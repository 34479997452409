export default function SplashScren() {
    return (
        <div className="splash-screen-wrapper">
            <img src="https://content.hococo.io/hubfs/2022%20Webflow_Website%20images/Text%20logo_green_new_Notion_.png" className="splash-logo" alt="logo" />
            <svg version="1.2" width="0" height="0" viewBox="0 0 740 600">
                <defs>
                    <clipPath id="mask">
                        <rect x="0" y="0" width="900" height="660" fill="#000" id="cool_shape">
                            <animate attributeType="XML" xlinkHref="#cool_shape" attributeName="x" from="-900" to="0"
                                dur="4s" repeatCount="indefinite" />
                        </rect>
                    </clipPath>
                </defs>
            </svg>
        </div>
    )
}