import { useState } from "react";
import TextInput, { InputTypes } from "../TextInput";
import { SelectInput } from "../SelectInput";
import { FormEvent } from "react";
import { Button, HtmlButtonTypes } from "../Button";
import { Modal } from "../Modal";
import { AddCorporationResponse, createCorporation } from "../../api/tools";
import { Alert, AlertTypes } from "../Alert";
import { Table } from "../Table";

export function AddCorporationForm() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [zip, setZip] = useState('');
    const [country, setCountry] = useState('Denmark');
    const [city, setCity] = useState('');
    const [corporation, setCorporation] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [errorResponse, setErrorResponse] = useState('');
    const [successResponse, setSuccessResponse] = useState({} as AddCorporationResponse);

    function handleSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();
        setShowModal(true);
    }

    async function submit(e: Event) {
        e.preventDefault();
        resetState();
        const response = await createCorporation({
            firstName,
            lastName,
            email,
            phone,
            address,
            zip,
            corporation,
            country,
            city
        });

        if (response?.exception) {
            setErrorResponse(response?.message as string);
        } else {
            setSuccessResponse(response)
        }
    }

    function resetState() {
        setErrorResponse('');
        setSuccessResponse({} as AddCorporationResponse);
        setShowModal(false);
    }

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className="card mb-4 full-width">
                        <h5 className="card-header">Create corporation</h5>
                        <form className="card-body" onSubmit={(e: FormEvent<HTMLFormElement>) => handleSubmit(e)}>
                            <div className="row g-3">
                                <div className="col-md-6">
                                    <TextInput
                                        onValue={(value: string) => setFirstName(value)}
                                        label="First name"
                                        placeholder="John"
                                        description="Main user first name"
                                        htmlOptions={{
                                            required: true
                                        }}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <TextInput
                                        onValue={(value: string) => setLastName(value)}
                                        label="Last name"
                                        placeholder="Doe"
                                        description="Main user last name"
                                        htmlOptions={{
                                            required: true
                                        }}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <TextInput
                                        onValue={(value: string) => setEmail(value)}
                                        label="Email"
                                        placeholder="customer@corporation.com"
                                        description="Main user email"
                                        type={InputTypes.EMAIL}
                                        htmlOptions={{
                                            required: true
                                        }}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <TextInput
                                        onValue={(value: string) => setPhone(value)}
                                        label="Phone"
                                        placeholder="+45 12 34 56 78"
                                        description="Main user phone number"
                                        type={InputTypes.TELEPHONE}
                                        htmlOptions={{
                                            required: true
                                        }}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <TextInput
                                        onValue={(value: string) => setAddress(value)}
                                        label="Address"
                                        placeholder="Corportation Vej 31"
                                        description="Main user adresss"
                                        htmlOptions={{
                                            required: true
                                        }}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <TextInput
                                        onValue={(value: string) => setZip(value)}
                                        label="Zip"
                                        placeholder="2300"
                                        description="Main user adresss"
                                        htmlOptions={{
                                            required: true
                                        }}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <TextInput
                                        onValue={(value: string) => setCity(value)}
                                        label="City"
                                        placeholder="Copenhagen"
                                        description="Main user city"
                                        htmlOptions={{
                                            required: true
                                        }}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <SelectInput
                                        label="Country"
                                        options={
                                            [
                                                { value: 'Denmark', label: 'Denmark' },
                                                { value: 'Netherlands', label: 'Netherlands' },
                                                { value: 'Belgium', label: 'Belgium' },
                                                { value: 'Sweden', label: 'Sweden' },
                                                { value: 'United Kingdom', label: 'United Kingdom' },
                                                { value: 'Philippines', label: 'Philippines' },
                                                { value: 'Spain', label: 'Spain' }
                                            ]
                                        }
                                        placeholder={
                                            {
                                                value: 'Denmark',
                                                label: 'Denmark'
                                            }
                                        }
                                        value={country}
                                        onValue={(option: any) => setCountry(option.value)}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <TextInput
                                        onValue={(value: string) => setCorporation(value)}
                                        label="Corporation"
                                        placeholder="Hococo Corp"
                                        description="Corporation name"
                                        htmlOptions={{
                                            required: true
                                        }}
                                    />
                                </div>
                                <div className="pt-4">
                                    <Button htmlType={HtmlButtonTypes.SUBMIT} text="Add corporation" onPress={() => { }} />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {errorResponse && (
                <div className="pt-4">
                    <Alert type={AlertTypes.DANGER} text={errorResponse} />
                </div>
            )}
            {successResponse?.password && (
                <>
                <div className="pt-4">
                    <Alert type={AlertTypes.SUCCESS} text={`Corporation successfully created. User created:`} />
                </div>
                    <div className="pt-2 card mb-4 p-4">
                        <Table
                            cols={
                                [
                                    {
                                        name: 'email'
                                    },
                                    {
                                        name: 'password'
                                    }
                                ]
                            }
                            rows={
                                [
                                    {
                                        cells: [
                                            { content: successResponse.user?.email },
                                            { content: successResponse.password }
                                        ]
                                    }
                                ]
                            }
                        />
                    </div>
                </>
            )}
            {showModal && (
                <Modal title="Confirm action" onConfirm={(e: Event) => submit(e)} onCancel={() => setShowModal(false)}>
                    <p>Are you sure you want to create this corporation?</p>
                </Modal>
            )}
        </>
    )
}