import { useState } from "react";
import TextInput, { InputTypes } from "../TextInput";
import { SelectInput } from "../SelectInput";
import { addUserToProperty, addUserToPropertyOverview, AddUserToPropertyOverviewResponse } from "../../api/tools";
import { Alert, AlertTypes } from "../Alert";
import { Modal } from "../Modal";
import { Table } from "../Table";
import { Button, ButtonTypes } from "../Button";

export default function AddUserToPropertyForm() {
    const [userId, setUserId] = useState(0);
    const [propertyId, setPropertyId] = useState(0);
    const [roleId, setRoleId] = useState(2);
    const [errorResponse, setErrorResponse] = useState('');
    const [successReponse, setSuccessResponse] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [overview, setOverview] = useState({} as AddUserToPropertyOverviewResponse | null);

    async function handleSubmit(e: Event) {
        e.preventDefault();
        const overview = await addUserToPropertyOverview({ userId, propertyId, roleId });
        setOverview(overview);
        setShowModal(true);
    }

    async function submit(e: Event) {
        e.preventDefault();
        resetState();
        const response = await addUserToProperty({ userId, propertyId, roleId });
        if (response?.exception) {
            setErrorResponse(response?.message);
        } else {
            setSuccessResponse(`User: ${userId} was successfully added to the property: ${propertyId}`);
        }
    }

    function resetState() {
        setErrorResponse('');
        setSuccessResponse('');
        setOverview(null);
        setShowModal(false);
    }

    function isFormValid() {
        return userId && propertyId && roleId;
    }

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className="card mb-4 full-width">
                        <h5 className="card-header">Add user to property</h5>
                        <div className="card-body">
                            <div className="row g-3">
                                <div className="col-md-6">
                                    <TextInput
                                        onValue={(value: number) => setUserId(value)}
                                        label="User ID"
                                        placeholder="1230"
                                        description="User to be added to the property"
                                        type={InputTypes.NUMBER}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <TextInput
                                        onValue={(value: number) => setPropertyId(value)}
                                        label="Property ID"
                                        placeholder="432"
                                        description="Property where the user is going to be added"
                                        type={InputTypes.NUMBER}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <SelectInput
                                        options={[
                                            {
                                                value: 2,
                                                label: 'Property owner'
                                            },
                                            {
                                                value: 7,
                                                label: 'Property tenant'
                                            }
                                        ]}
                                        placeholder={{
                                            value: 2,
                                            label: 'Property owner'
                                        }}
                                        label="User role"
                                        description="The role that the user is going to have in the property"
                                        value={roleId}
                                        onValue={(option: any) => setRoleId(option.value)}
                                    />
                                </div>
                            </div>
                            <div className="pt-4">
                                <Button type={ButtonTypes.PRIMARY} text="Add user" className={!isFormValid() ? 'disabled' : ''} onPress={(e: Event) => handleSubmit(e)} />
                            </div>
                            {errorResponse && (
                                <div className="pt-4">
                                    <Alert type={AlertTypes.DANGER} text={errorResponse} />
                                </div>
                            )}
                            {successReponse && (
                                <div className="pt-4">
                                    <Alert type={AlertTypes.SUCCESS} text={successReponse} />
                                </div>
                            )}
                            {showModal && (
                                <Modal
                                    title="Details about the action"
                                    onConfirm={(e: Event) => submit(e)}
                                    onCancel={(e: Event) => setShowModal(false)}
                                >
                                    {overview && (
                                        <>
                                            <Table
                                                cols={[
                                                    { name: 'User ID' },
                                                    { name: 'User name' },
                                                    { name: 'Property ID' },
                                                    { name: 'Property title' },
                                                    { name: 'Role' }
                                                ]}
                                                rows={[
                                                    {
                                                        cells: [
                                                            { content: `${overview?.user?.id}` },
                                                            { content: `${overview.user?.person?.first_name} ${overview.user?.person?.family_name}` },
                                                            { content: `${overview.property?.id}` },
                                                            { content: `${overview.property?.title_number}` },
                                                            { content: `${overview.role?.name}` }
                                                        ]
                                                    }
                                                ]}
                                            />
                                        </>
                                    )}
                                </Modal>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}