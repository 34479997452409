import { MutableRefObject, useRef, useState } from 'react';
import excelLogo from '../assets/excel-logo.svg';
import readXlsxFile, { Row } from 'read-excel-file';
import { Options, SelectInput } from './SelectInput';
import { importKnowledgeBase } from '../api/tools';
import { Spinner, SpinnerColor } from './Spinner';
import { Alert, AlertTypes } from './Alert';
import { importTranslations as importTranslationsRequest } from '../api/tools';

interface Prop {
    apiFields: any[],
    resourceId?: number | null,
    action: MapperActions,
    languageKey?: string
}

export enum MapperActions {
    IMPORT_KNOWLEDGE_BASE = 'import-knowledge-base',
    IMPORT_TRANSLATIONS = 'import-translations'
}

export function ExcelMapper({ apiFields, resourceId, action, languageKey = 'da' }: Prop) {

    const [filledRows, setFilledRows] = useState([]);
    const [excelMap, setExcelMap] = useState({});
    const [isImporting, setIsImporting] = useState(false);
    const [importSucceeded, setImportSucceeded] = useState(false);
    const [importProcessed, setImportProcessed] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const fileInputRef = useRef() as MutableRefObject<HTMLInputElement>;

    function handleChange(value: string, excelField: string) {
        if (excelField) {
            const excelObject: any = excelMap;
            (excelObject[excelField] as any) = value;
            setExcelMap({ ...excelObject });
        }
    }

    async function handleInputChange(e: any) {
        e.preventDefault();
        resetState();
        const files = fileInputRef.current.files;
        if (files?.length) {
            const file = files[0];
            if (file) {
                const rowsToFill: any[] = [];
                const rows: Row[] = await readXlsxFile(file);
                rows.forEach(row => {
                    const isEmptyRow = row.every(cell => cell === null)
                    if (!isEmptyRow) {
                        rowsToFill.push(row as any);
                    }
                })
                setFilledRows(rowsToFill as any);
            }
        }
    }

    function resetState() {
        setFilledRows([]);
        setExcelMap({});
        setIsImporting(false);
        setImportSucceeded(false);
        setImportProcessed(false);
        setErrorMessage('');
    }

    function openInputDialog(e: any) {
        e.preventDefault();
        fileInputRef.current.click();
    }

    async function handleSubmit(e: any) {
        e.preventDefault();
        switch (action) {
            case MapperActions.IMPORT_KNOWLEDGE_BASE:
                await importKb();
                break;
            case MapperActions.IMPORT_TRANSLATIONS:
                await importTranslations();
                break;
            default:
                await importKb();
                break;
        }
    }

    async function importTranslations() {
        const data = mapApiFields();
        setIsImporting(true);
        const response = await await importTranslationsRequest({ languageKey, data });
        if (response?.exception) {
            setErrorMessage(response?.message)
            setImportSucceeded(false);
            setImportProcessed(true);
            setIsImporting(false);
        } else {
            setImportSucceeded(true);
            setImportProcessed(true);
            setIsImporting(false);
        }
    }

    async function importKb() {
        const data = mapApiFields();
        setIsImporting(true);
        const response = await importKnowledgeBase({ projectId: resourceId as number, languageKey, data });
        if (response?.exception) {
            setErrorMessage(response?.message)
            setImportSucceeded(false);
            setImportProcessed(true);
            setIsImporting(false);
        } else {
            setImportSucceeded(true);
            setImportProcessed(true);
            setIsImporting(false);
        }
    }

    function mapApiFields() {
        const columns: string[] = filledRows[0];
        const contentRows = filledRows.slice(1);
        const apiMap = {};
        const apiData: any[] = [];
        Object.keys(excelMap).forEach(excelField => {
            const rowIndex = columns.indexOf(excelField)
            const apiField: any = (excelMap as any)[excelField];
            (apiMap as any)[apiField] = rowIndex
        })
        contentRows.forEach(row => {
            const apiRow = {};
            Object.keys(apiMap).forEach(field => {
                const valueIndex = (apiMap as any)[field];
                const value = row[valueIndex];
                (apiRow as any)[field] = value;
            })
            apiData.push(apiRow);
        })
        return apiData
    }

    function renderApiFields(excelIndex: number, excelField: string) {
        const selectOptions: Options[] = [];

        apiFields.forEach((apiField, index): void => {
            const selectOption = excelIndex === index && !(excelMap as any)[excelField];
            if (selectOption) {
                const excelObject: any = excelMap;
                (excelObject[excelField] as any) = apiField;
                setExcelMap({ ...excelObject });
            };
            selectOptions.push({
                value: apiField,
                label: apiField
            });
        })

        return (
            <SelectInput
                options={selectOptions}
                placeholder={
                    {
                        value: (excelMap as any)[excelField] || '',
                        label: (excelMap as any)[excelField] || 'N/A',
                        disabled: false
                    }
                }
                value={(excelMap as any)[excelField]}
                onValue={(option: any) => handleChange(option.value, excelField)}

            />
        )
    }

    return (
        <>
            <div className="file-select-wrapper">
                <div className="excel-button-wrapper" onClick={(e: any) => openInputDialog(e)}>
                    <img className="logo" src={excelLogo} alt="Excel logo" />
                    <span className="text">Import Excel file</span>
                </div>
            </div>
            <input ref={fileInputRef} onChange={(e) => handleInputChange(e)} type="file" id="input" style={{ display: 'none' }} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />

            {filledRows && filledRows.length ? (
                <div className='content-wrapper'>
                    {filledRows && filledRows.length ? (
                        <div className="table-wrapper">
                            <table className="re-excel-mapper-table col-9">
                                <tbody>
                                    {filledRows.map((row: any[], rowIndex: number) => (
                                        rowIndex === 0 ?
                                            <tr key={`tr-${rowIndex}`}>
                                                {row.map((value: string, thIndex: number) => (
                                                    <th key={`first-th-${thIndex}`}>{value}</th>
                                                ))}
                                            </tr> :
                                            <tr key={`tr-${rowIndex}`}>
                                                {row.map((value: string, tdIndex: number) => (
                                                    <td key={`td-${tdIndex}`}>{value}</td>
                                                ))}
                                            </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ) : null}
                    {filledRows && filledRows.length ? (
                        <div className="match-wrapper">
                            <p className="title">Please select the matching between the Excel column and the API field</p>
                            <div className="fields">
                                {filledRows.length && (filledRows[0] as any[]).map((field, index) => (
                                    <div key={index} className="field">
                                        <span className="name">{field}</span> maps → {renderApiFields(index, field)}
                                    </div>
                                ))}
                            </div>
                            <div className="action-wrapper">
                                <button id="action-button" className="button" type="button" onClick={(e: any) => handleSubmit(e)}>Send to the API</button>
                            </div>
                        </div>
                    ) : null}
                </div>
            ) : null}
            {
                isImporting || importProcessed ? (
                    <div className='mt-4 mb-4'>
                        {isImporting ? (
                            <Spinner color={SpinnerColor.PRIMARY} />
                        ) : null}
                        {importProcessed ?
                            importSucceeded ?
                                (
                                    <Alert type={AlertTypes.SUCCESS} text={'Import succeeded'} />
                                ) : (
                                    <Alert type={AlertTypes.DANGER} text={errorMessage} />
                                ) : null}
                    </div>
                ) : null
            }
        </>
    )
}