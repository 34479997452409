import { FormEvent, useEffect, useRef, useState } from 'react';
import TextInput from '../TextInput';
import { Button, HtmlButtonTypes } from '../Button';
import { useSearchParams } from 'react-router-dom';
import { reportSalesforceSyncFalsePositive } from '../../api/tools';
import { Alert, AlertTypes } from '../Alert';

export default function SalesforceSyncFalsePositive() {

    const [leaseObject, setLeaseObject] = useState('');
    const [salesforceTenants, setSalesforceTenants] = useState('');
    const [hococoTenants, setHococoTenants] = useState('');
    const [successResponse, setSuccessResponse] = useState('');
    const [errorResponse, setErrorResponse] = useState('');
    const containerRef = useRef(null as any);
    const [searchParams] = useSearchParams();

    useEffect(() => {
        setSalesforceTenants(String(searchParams.get('salesforceTenants')));
        setHococoTenants(String(searchParams.get('hococoTenants')));
        setLeaseObject(String(searchParams.get('leaseObject')));
        if (searchParams.get('salesforceTenants') && containerRef) {
            containerRef.current?.scrollIntoView();
        }
    }, [searchParams])

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        resetState();
        try {
            await reportSalesforceSyncFalsePositive({
                leaseObject,
                salesforceTenants,
                hococoTenants
            });
            setSuccessResponse(`The false positive was successfully reported.`);
        } catch (e) {
            setErrorResponse(`There was an error while trying to report the false positive.`);
        }
    }
    
    const resetState = () => {
        setErrorResponse('');
        setSuccessResponse('');
    }

    return (
        <>
            <div className="card p-4 full-width mb-4" id="salesforce-false-positive" ref={containerRef}>
                <h5 className="card-header">Report Salesforce tenants sync as false positive</h5>
                <form className="card-body" onSubmit={(e: FormEvent<HTMLFormElement>) => handleSubmit(e)}>
                            <div className="row g-3">
                                <div className="col-md-4">
                                    <TextInput
                                        onValue={(value: string) => setLeaseObject(value)}
                                        label="Lease object"
                                        placeholder="X85GHJLJ"
                                        description="Salesforce lease object"
                                        htmlOptions={{
                                            required: true,
                                            defaultValue: leaseObject
                                        }}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <TextInput
                                        onValue={(value: string) => setSalesforceTenants(value)}
                                        label="Salesforce tenants"
                                        placeholder="['jane.doe@hococo.io']"
                                        description="Tenants present in Salesforce in the lease object"
                                        htmlOptions={{
                                            required: true,
                                            defaultValue: salesforceTenants
                                        }}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <TextInput
                                        onValue={(value: string) => setHococoTenants(value)}
                                        label="Hococo tenants"
                                        placeholder="['jane.doe@hococo.io']"
                                        description="Tenants present in Hococo in the lease object"
                                        htmlOptions={{
                                            required: true,
                                            defaultValue: hococoTenants
                                        }}
                                    />
                                </div>
                                {errorResponse && (
                                    <div className="pt-4">
                                        <Alert type={AlertTypes.DANGER} text={errorResponse} />
                                    </div>
                                )}
                                {successResponse && (
                                    <div className="pt-4">
                                        <Alert type={AlertTypes.SUCCESS} text={successResponse} />
                                    </div>
                                )}
                                <div className="pt-4">
                                    <Button htmlType={HtmlButtonTypes.SUBMIT} text="Submit report" onPress={() => { }} />
                                </div>
                            </div>
                        </form>
            </div>
        </>
    )
}