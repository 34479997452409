import { getWithAuth, postBlobWithAuth, postWithAuth } from "../helpers/request"

export interface AddUserToPropertyRequest {
    userId: number,
    propertyId: number,
    roleId: number
}

export interface SendWelcomeEmailsRequest {
    corporationId: number,
    secret: string
}

export interface SendProjectWelcomeEmailsRequest {
    projectId: number
}

export interface SendWelcomeEmailsResponse {
    tenantsMissing: number
}

export interface SendProjectWelcomeEmailsResponse {
    alreadySent: number,
    failedEmails: any[],
    invitationSent: number,
    residents: number,
    skipLoggedIn: boolean,
    exception?: string,
    message?: string
}

export interface AddUserToPropertyOverviewResponse {
    user: any,
    property: any,
    role: any
}

export interface AddUserToCorporationRequest {
    userId: number,
    corporationId: number,
    roleId: number
}

export interface AddUserToCorporationOverviewResponse {
    corporation: any,
    user: any,
    role: any
}

export interface AddCorporationRequest {
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    address: string,
    zip: string,
    city: string,
    country: string,
    corporation: string
}

export interface AddCorporationResponse {
    user: any,
    password: string,
    exception?: string,
    message?: string
}

export interface ImportKnowledgeBaseRequest {
    projectId: number,
    languageKey: string,
    data: any[]
}

export interface copyKnowledgeBaseRequest {
    sourceProjectId: number,
    targetProjectId: number,
    updateExisting: boolean
}

export interface downloadKnowledgeBaseRequest {
    projectId: number
}


export interface ImportTranslationsRequest {
    languageKey: string,
    data: any[]
}

export interface GetCorporationProjects {
    corporationId: number
}

export interface RevertMoveOutRequest {
    tenancyPeriodTenantId: number
}

export interface ImportIntegrationFileRequest {
    integrationFileId: number
}

export interface RevertMoveOutRequestOverviewResponse {
    tenant: any,
    unit: any
}

export interface GetCorporationIntegrations {
    corporationId: number,
}

export interface GetCorporationIntegrationFiles {
    corporationId: number,
    integrationTypeId: number
}

export interface ResetPaymentRequest {
    userId: number
}

export interface GetIntegrationJobLog {
    backofficeReferenceId: string,
}

export interface GetIntegrationJobFiles {
    backofficeReferenceId: string
}

export interface GenerateTenancyReport {
    secret: string,
    autoFixProblems: boolean,
    fromDate?: string,
    toDate?: string,
    corporationIds?: number[],
}

export interface ReportSalesforceSyncFalsePositive {
    leaseObject: string,
    salesforceTenants: string,
    hococoTenants: string
}

const TOOLS_ENDPOINTS = {
    ADD_USER_TO_PROPERTY: '/api/system/tools/property-user/add',
    ADD_USER_TO_PROPERTY_OVERVIEW: '/api/system/tools/property-user/add/overview',
    ADD_USER_TO_CORPORATION: '/api/system/tools/corporation-user/add',
    ADD_USER_TO_CORPORATION_OVERVIEW: '/api/system/tools/corporation-user/add/overview',
    CREATE_CORPORATION: '/api/system/tools/corporation-create',
    IMPORT_KNOWLEDGE_BASE: (projectId: number) => `/api/system/tools/projects/${projectId}/knowledge-base/import`,
    COPY_KNOWLEDGE_BASE: () => `/api/system/tools/knowledge-base/copy`,
    DOWNLOAD_KNOWLEDGE_BASE: () => `/api/system/tools/knowledge-base/download`,
    GET_ALL_CORPORATIONS: '/api/system/tools/corporations',
    SEND_WELCOME_EMAILS: '/api/system/jobs/schedule-welcome-emails',
    SEND_PROJECT_WELCOME_EMAILS: (projectId: number) => `/api/system/tools/welcome-emails/${projectId}`,
    IMPORT_TRANSLATIONS: '/api/system/import-translations',
    GET_CORPORATION_PROJECTS: (corporationId: number) => `/api/system/tools/projects/${corporationId}`,
    REVERT_MOVE_OUT_OVERVIEW: '/api/system/tools/move-out/revert/overview',
    REVERT_MOVE_OUT: '/api/system/tools/move-out/revert',
    RESET_PAYMENT: '/api/system/tools/payment-info/reset',
    GET_CORPORATION_INTEGRATIONS: (corporationId: number) => `/api/system/tools/integrations/${corporationId}`,
    GET_CORPORATION_INTEGRATION_FILES: (corporationId: number, integrationTypeId: number) => `/api/system/tools/integrations/${corporationId}/${integrationTypeId}/data`,
    IMPORT_INTEGRATION_FILE: (integrationFileId: number) => `/api/admin/integrations/file/${integrationFileId}/data/import`,
    GET_INTEGRATION_JOB_LOG: (backofficeReferenceId: string) => `/api/admin/integrations/${backofficeReferenceId}/get-log`,
    GET_INTEGRATION_JOB_FILES: (backofficeReferenceId: string) => `/api/admin/integrations/jobs/${backofficeReferenceId}/files`,
    GENERATE_TENANCY_REPORT: () => `/api/system/jobs/tenancy-period-quality`,
    REPORT_SALESFORCE_SYNC_FALSE_POSITIVE: () => `/api/system/tools/integrations/salesforce-sync-false-positive`,
    BACKUP_DATABASE: `/api/system/tools/backup-database`,
}

export async function addUserToProperty(request: AddUserToPropertyRequest): Promise<any> {
    const body = JSON.stringify(request);
    return await postWithAuth(TOOLS_ENDPOINTS.ADD_USER_TO_PROPERTY, { body });
}

export async function addUserToPropertyOverview(request: AddUserToPropertyRequest): Promise<AddUserToPropertyOverviewResponse> {
    const body = JSON.stringify(request);
    return await postWithAuth(TOOLS_ENDPOINTS.ADD_USER_TO_PROPERTY_OVERVIEW, { body });
}

export async function addUserToCorporation(request: AddUserToCorporationRequest): Promise<any> {
    const body = JSON.stringify(request);
    return await postWithAuth(TOOLS_ENDPOINTS.ADD_USER_TO_CORPORATION, { body });
}

export async function addUserToCorporationOverview(request: AddUserToCorporationRequest): Promise<AddUserToCorporationOverviewResponse> {
    const body = JSON.stringify(request);
    return await postWithAuth(TOOLS_ENDPOINTS.ADD_USER_TO_CORPORATION_OVERVIEW, { body });
}

export async function createCorporation(request: AddCorporationRequest): Promise<AddCorporationResponse> {
    const body = JSON.stringify(request);
    return await postWithAuth(TOOLS_ENDPOINTS.CREATE_CORPORATION, { body });
}

export async function importKnowledgeBase(request: ImportKnowledgeBaseRequest) {
    const body = JSON.stringify(request);
    return await postWithAuth(TOOLS_ENDPOINTS.IMPORT_KNOWLEDGE_BASE(request.projectId), { body });
}

export async function copyKnowledgeBase(request: copyKnowledgeBaseRequest) {
    const body = JSON.stringify(request);
    return await postWithAuth(TOOLS_ENDPOINTS.COPY_KNOWLEDGE_BASE(), { body });
}

export async function downloadKnowledgeBase(request: downloadKnowledgeBaseRequest) {
    const body = JSON.stringify(request);
    return await postBlobWithAuth(TOOLS_ENDPOINTS.DOWNLOAD_KNOWLEDGE_BASE(), { body, fileName: `knowledge-base-${request.projectId}-${new Date().toLocaleDateString()}.xlsx` });
}

export async function getAllCorporations(): Promise<any[]> {
    return await getWithAuth(TOOLS_ENDPOINTS.GET_ALL_CORPORATIONS);
}

export async function sendWelcomeEmails(request: SendWelcomeEmailsRequest): Promise<SendWelcomeEmailsResponse> {
    const body = JSON.stringify(request);
    return await postWithAuth(TOOLS_ENDPOINTS.SEND_WELCOME_EMAILS, { body, retryOnTimeOut: true });
}

export async function sendProjectWelcomeEmails(request: SendProjectWelcomeEmailsRequest): Promise<SendProjectWelcomeEmailsResponse> {
    return await postWithAuth(TOOLS_ENDPOINTS.SEND_PROJECT_WELCOME_EMAILS(request.projectId));
}

export async function importTranslations(request: ImportTranslationsRequest): Promise<any> {
    const body = JSON.stringify(request);
    return await postWithAuth(TOOLS_ENDPOINTS.IMPORT_TRANSLATIONS, { body });
}

export async function getCorporationProjects(request: GetCorporationProjects): Promise<any> {
    return await getWithAuth(TOOLS_ENDPOINTS.GET_CORPORATION_PROJECTS(request.corporationId));
}

export async function revertMoveOutOverview(request: RevertMoveOutRequest): Promise<RevertMoveOutRequestOverviewResponse> {
    const body = JSON.stringify(request);
    return await postWithAuth(TOOLS_ENDPOINTS.REVERT_MOVE_OUT_OVERVIEW, { body });
}

export async function revertMoveOut(request: RevertMoveOutRequest): Promise<any> {
    const body = JSON.stringify(request);
    return await postWithAuth(TOOLS_ENDPOINTS.REVERT_MOVE_OUT, { body });
}

export async function resetPayment(request: ResetPaymentRequest): Promise<any> {
    const body = JSON.stringify(request);
    return await postWithAuth(TOOLS_ENDPOINTS.RESET_PAYMENT, { body });
}

export async function databaseBackup(): Promise<any> {
    return await postWithAuth(TOOLS_ENDPOINTS.BACKUP_DATABASE);
}

export async function getCorporationIntegrations(request: GetCorporationIntegrations): Promise<any> {
    return await getWithAuth(TOOLS_ENDPOINTS.GET_CORPORATION_INTEGRATIONS(request.corporationId));
}

export async function getCorporationIntegrationFiles(request: GetCorporationIntegrationFiles): Promise<any> {
    return await getWithAuth(TOOLS_ENDPOINTS.GET_CORPORATION_INTEGRATION_FILES(request.corporationId, request.integrationTypeId));
}

export async function importIntegrationFile(request: ImportIntegrationFileRequest): Promise<any> {
    const body = JSON.stringify(request);
    return await postWithAuth(TOOLS_ENDPOINTS.IMPORT_INTEGRATION_FILE(request.integrationFileId), { body });
}

export async function getIntegrationJobLog(request: GetIntegrationJobLog): Promise<any> {
    return await getWithAuth(TOOLS_ENDPOINTS.GET_INTEGRATION_JOB_LOG(request.backofficeReferenceId));
}

export async function getIntegrationJobFiles(request: GetIntegrationJobFiles): Promise<any> {
    const body = JSON.stringify(request);
    const fileName = request.backofficeReferenceId;
    return await postBlobWithAuth(TOOLS_ENDPOINTS.GET_INTEGRATION_JOB_FILES(request.backofficeReferenceId), { body, fileName });
}

export async function generateTenancyReport(request: GenerateTenancyReport): Promise<any> {
    const body = JSON.stringify(request);
    return await postWithAuth(TOOLS_ENDPOINTS.GENERATE_TENANCY_REPORT(), { body });
}

export async function reportSalesforceSyncFalsePositive(request: ReportSalesforceSyncFalsePositive): Promise<any> {
    const body = JSON.stringify(request);
    return await postWithAuth(TOOLS_ENDPOINTS.REPORT_SALESFORCE_SYNC_FALSE_POSITIVE(), { body });
}