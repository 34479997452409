interface Prop {
    label: string,
    checked: boolean,
    onChange: Function
}

export function Checkbox({ label, checked, onChange }: Prop) {
    return (
        <div className="form-check">
            <input className="form-check-input" onChange={(e: any) => onChange(!checked, e)} type="checkbox" value={checked.toString()} id="checkbox" checked={checked} />
            <label className="form-check-label" htmlFor="checkbox">
                { label }
            </label>
        </div>
    )
}