import { useState, useEffect } from 'react';
import { Routes, Route } from "react-router-dom";
import LoginContainer from './containers/Login';
import DashboardContainer from './containers/Dashboard'; 
import AuthWrapper from './containers/AuthWrapper';
import { isUserAuthenticated } from './api/auth';
import SplashScren from './components/SplashScreen';
import { delay } from './helpers/misc';
import { getAllCorporations } from './api/tools';
import { STORAGE_KEYS, setItem } from './helpers/storage';

export default function App() {

    const [showPrivateRoutes, setShowPrivateRoutes] = useState(false);
    const [isValidating, setIsValidating] = useState(true);

    useEffect(() => {
        validateUser().then(isAuthenticated => {
            if(isAuthenticated) {
                setShowPrivateRoutes(true);
                setIsValidating(false);
            } else {
                setIsValidating(false);
            }
        })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    async function validateUser(): Promise<boolean> {
        const isAuthenticated = await isUserAuthenticated();
        if (isAuthenticated) {
            await setCorporations();
            await delay(1500);
        }
        return isAuthenticated;
    }

    async function setCorporations() {
        const corporations = await getAllCorporations();
        if (corporations?.length) {
            await setItem(STORAGE_KEYS.ALL_CORPORATIONS, JSON.stringify(corporations));
        }
    }

    function renderPrivateRoutes() {
        return (
            <AuthWrapper>
                <Routes>
                    <Route index path='/' Component={DashboardContainer} />
                    <Route path='/dashboard' Component={DashboardContainer} />
                    <Route path='*' Component={DashboardContainer} />
                </Routes>
            </AuthWrapper>
        )
    }

    function renderGuestRoutes() {
        return (
            <Routes>
                <Route index path='/' Component={LoginContainer} />
                <Route path='/login' Component={LoginContainer} />
                <Route path='*' Component={LoginContainer} />
            </Routes>
        )
    }

    function renderSplashScreen() {
        return (
            <SplashScren />
        )
    }


    return isValidating ? renderSplashScreen() : showPrivateRoutes ? renderPrivateRoutes() : renderGuestRoutes()
}