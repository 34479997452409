import { STORAGE_KEYS } from "./storage";
import { getItem } from "./storage";

export async function getAllCorporations(): Promise<any> {
    const response = await getItem(STORAGE_KEYS.ALL_CORPORATIONS);

    if (response) {
        const corporations = JSON.parse(response).map((corporation: any) => {
            return {
                value: corporation.id,
                label: corporation.name
            }
        });
        return corporations;
    }

    return null;
}