interface Props {
    onValue: Function,
    description?: string,
    label?: string,
    placeholder?: string,
    className?: string,
    type?: InputTypes,
    htmlOptions?: Object
}

export enum InputTypes {
    TEXT = 'text',
    NUMBER = 'number',
    EMAIL = 'email',
    TELEPHONE = 'tel',
    DATE = 'datetime-local',
    DATE_TIME = 'datetime-local'
}

export default function TextInput({ onValue, description, label, placeholder, className, type = InputTypes.TEXT, htmlOptions = {} }: Props) {
    return (
        <div>
            <label htmlFor="defaultFormControlInput" className="form-label">{label}</label>
            <input type={type} onChange={(e) => onValue(e.target.value)} {...htmlOptions} className={`form-control ${className}`} placeholder={placeholder} aria-describedby="defaultFormControlHelp" />
            <div id="defaultFormControlHelp" className="form-text">{description}</div>
        </div>
    )
}