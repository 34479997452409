import { useState } from "react";
import TextInput, { InputTypes } from "../TextInput";
import { Button, ButtonTypes } from "../Button";
import { Alert, AlertTypes } from "../Alert";
import { resetPayment } from "../../api/tools";

export default function ResetPayment() {
    const [userId, setUserId] = useState(0);
    const [errorResponse, setErrorResponse] = useState('');
    const [successReponse, setSuccessResponse] = useState('');

    function isFormValid() {
        return userId;
    }

    async function submit(e: Event) {
        e.preventDefault();
        resetState();
        const response = await resetPayment({ userId });
        if (response?.exception) {
            setErrorResponse(response?.message);
        } else {
            setSuccessResponse(response?.message);
        }
    }

    function resetState() {
        setErrorResponse('');
        setSuccessResponse('');
    }

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className="card mb-4 full-width">
                        <h5 className="card-header">Reset payment info</h5>
                        <div className="card-body">
                            <div className="row g-3">
                                <div className="col-md-6">
                                    <TextInput
                                        onValue={(value: number) => setUserId(value)}
                                        label="User ID"
                                        placeholder="8067"
                                        description="ID of the user"
                                        type={InputTypes.NUMBER}
                                    />
                                </div>
                            </div>
                            <div className="pt-4">
                                <Button type={ButtonTypes.PRIMARY} text="Reset payment" className={!isFormValid() ? 'disabled' : ''} onPress={(e: Event) => submit(e)} />
                            </div>
                            {errorResponse && (
                                <div className="pt-4">
                                    <Alert type={AlertTypes.DANGER} text={errorResponse} />
                                </div>
                            )}
                            {successReponse && (
                                <div className="pt-4">
                                    <Alert type={AlertTypes.SUCCESS} text={successReponse} />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}