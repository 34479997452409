import { useEffect, useState } from 'react';
import { getAllCorporations } from '../../helpers/corporation';
import { SelectInput } from '../SelectInput';
import { downloadKnowledgeBase, getCorporationProjects } from '../../api/tools';
import { buildCorporationProjectsOptions } from '../../helpers/project';
import { Button, ButtonTypes } from '../Button';
import { Alert, AlertTypes } from '../Alert';
import { Spinner, SpinnerColor } from '../Spinner';

export default function DownloadKnowledgeBase() {

    const [sourceProjectId, setSourceProjectId] = useState(0);
    const [corporationId, setCorporationId] = useState(0);
    const [allCorporations, setAllCorporations] = useState([]);
    const [projects, setProjects] = useState([]);
    const [successResponse, setSuccessResponse] = useState('');
    const [errorResponse, setErrorResponse] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getAllCorporations().then(corporations => {
            if (corporations) {
                setAllCorporations(corporations as any);
            }
        })
    }, [])

    useEffect(() => {
        if (corporationId > 0) {
            setProjects([]);
            setSourceProjectId(0);
            getCorporationProjects({ corporationId }).then((projects: any[]) => {
                if (projects?.length) {
                    setProjects(
                        buildCorporationProjectsOptions(projects)
                    );
                }
            });
        }
    }, [corporationId])

    const submit = (async (e: Event) => {
        e.preventDefault();
        resetState();
        setIsLoading(true);
        try {
            await downloadKnowledgeBase({ projectId: sourceProjectId });
            setSuccessResponse('Knowledge base download was successfully triggered.');
            setIsLoading(false);
        } catch (err) {
            setErrorResponse(err as string);
            setIsLoading(false);
        }
    })

    const resetState = () => {
        setSuccessResponse('');
        setErrorResponse('');
        setIsLoading(false);
    }

    return (
        <>
            <div className='row'>
                <div className='col-md-12'>
                    <div className="card p-4 mb-4 full-width">
                        <h5 className="card-header">Download knowledge base from project</h5>
                        <div className='card-body'>
                            <div className='row g-3'>
                                <div className="col-md-4">
                                    <SelectInput
                                        label="Corporation"
                                        options={
                                            allCorporations
                                        }
                                        placeholder={
                                            {
                                                value: '',
                                                label: 'Select corporation'
                                            }
                                        }
                                        value={corporationId}
                                        onValue={(option: any) => setCorporationId(option.value)}
                                    />
                                </div>
                                <div className='mb-4 col-md-4'>
                                    {
                                        corporationId &&
                                            corporationId > 0 &&
                                            projects.length > 0 ? (
                                            <SelectInput
                                                label="Source project"
                                                options={
                                                    projects
                                                }
                                                placeholder={
                                                    {
                                                        value: '',
                                                        label: 'Select project'
                                                    }
                                                }
                                                value={sourceProjectId}
                                                onValue={(option: any) => setSourceProjectId(option.value)}
                                            />

                                        ) : corporationId ? <p>Loading projects...</p> : null}
                                </div>
                                {
                                    corporationId && sourceProjectId ? (
                                        <div>
                                            <Button type={ButtonTypes.PRIMARY} text="Download knowledge base" className={!corporationId ? 'disabled' : ''} onPress={(e: Event) => submit(e)} />
                                        </div>
                                    ) : null
                                }
                                {errorResponse && (
                                    <div className="pt-4">
                                        <Alert type={AlertTypes.DANGER} text={errorResponse} />
                                    </div>
                                )}
                                {isLoading && (
                                    <Spinner color={SpinnerColor.PRIMARY} />
                                )}
                                {successResponse && (
                                    <div className="pt-4">
                                        <Alert type={AlertTypes.SUCCESS} text={successResponse} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}