interface Prop {
    rows: Row[],
    cols: Col[]
}

interface Row {
    cells: Cell[]
}

interface Col {
    name: string
}

interface Cell {
    icon?: string,
    content: string
}

export function Table({ rows, cols }: Prop) {
    return (
        <div className="table-responsive">
            <table className="table">
                <thead>
                    <tr>
                        {cols.map((col: Col, colIndex: number) => (
                            <th key={`col-${colIndex}`}>{col.name}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {rows.map((row: Row, rowIndex: number) => (
                        <tr key={`row-${rowIndex}`}>
                            {row.cells.map((cell: Cell, cellIndex: number) => (
                                <td key={`cell-${cellIndex}`}>{
                                    cell.icon && (
                                        <i className="fab fa-angular fa-lg text-danger me-3" />
                                    )
                                }
                                    {cell.content}
                                </td>

                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}