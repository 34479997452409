import { ExcelMapper, MapperActions } from '../ExcelMapper';

interface Prop {
    apiFields: string[]
}

export default function ImportTranslations({ apiFields }: Prop) {

    return (
        <>
            <div className="card p-4 full-width mb-4">
                <h5 className="card-header">Import translations</h5>

                <div className="p-4 mt-n4">
                    <ExcelMapper apiFields={apiFields} action={MapperActions.IMPORT_TRANSLATIONS} />
                </div>
            </div>
        </>
    )
}