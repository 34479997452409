import { getUserAccessToken } from "../api/auth";
import { logout } from "./user";

export enum HOCOCO_API_BASE_URL {
    STAGING = 'https://api-staging.hococo.io',
    PRODUCTION = 'https://api.hococo.io',
    LOCAL = 'https://api-dev.hococo.io',
    ASIA = 'https://sea-api.hococo.io'
}

export enum HOCOCO_JOB_SECRETS {
    STAGING = 'W4guTyYqrvWV04svpKkKEX9NpS0iUwkdXeyex0ljCc3YOwhZOmqv94lZbqockULhnl1JGj05os1Jq3BWpOSu40Do9R1L6Ux5upqCdLkB3Bj2JRMnECUfCtm5DGaWiMNo',
    PRODUCTION = 'oJZDkyCxeEE3wwk7b1jApLFLotlCJX7F3TAeuZ06fXqcLZ3i8fczxOf4B8Equ3unWDz8m5hD0q4fkx00Hglh2TD6G776JurVR4u2lV07El5UZIej0SNkADDHbjnpvGCo',
    LOCAL = 'W4guTyYqrvWV04svpKkKEX9NpS0iUwkdXeyex0ljCc3YOwhZOmqv94lZbqockULhnostemados1Jq3BWpOSu40Do9R1L6Ux5upqCdLkB3Bj2JRMnECUfCtm5DGaWiMNo',
    ASIA = 'oJZDkyCxeEE3wwk7b1jApLFLotlCJX7F3TAeuZ06fXqcLZ3i8fczxOf4B8Equ3unWDz8m5hD0q4fkx00Hglh2TD6G776JurVR4u2lV07El5UZIej0SNkADDHbjnpvGCo'
}

export enum HOCOCO_ADMIN_BASE_URL {
    STAGING = 'https://admin-staging.hococo.io',
    PRODUCTION = 'https://admin.hococo.io',
    LOCAL = 'https://admin-dev.hococo.io:3000',
    ASIA = 'https://sea-admin.hococo.io'
}

export enum API_ENVIRONMENTS {
    STAGING = 'STAGING',
    PRODUCTION = 'PRODUCTION',
    LOCAL = 'LOCAL',
    ASIA = 'ASIA'
}

export enum HttpStatusCode {
    OK = 200,
    UNAUTHORIZED = 401,
    TIMEOUT = 408
}

export interface RequestException {
    responseCode: HttpStatusCode
}

export interface CustomRequestInit extends RequestInit {
    logoutOn401?: boolean,
    retryOnTimeOut?: boolean,
    maxRetries?: number,
    fileName?: string
}

export const API_ENV = process.env.REACT_APP_API_ENV as API_ENVIRONMENTS || API_ENVIRONMENTS.LOCAL;

const DEFAULT_REQUEST_OPTIONS: CustomRequestInit = {
    logoutOn401: true,
    headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json, text/plain, */*'
    },
    maxRetries: 3
}

export async function blobRequest(endpoint: string, options: CustomRequestInit, currentAttempt = 0): Promise<any> {
    const url = `${HOCOCO_API_BASE_URL[API_ENV]}${endpoint}`;
    return fetch(url, options)
        .then(async response => {
            if (response.status === HttpStatusCode.UNAUTHORIZED && options.logoutOn401 ) {
                await logout();
            }
            return response.blob();
        })
        .then(blob => {
            // Create a URL for the Blob data
            const url = window.URL.createObjectURL(blob);

            // Create a link element to trigger the download
            const a = document.createElement('a');
            a.href = url;
            a.download = options.fileName || 'integration-job-file.zip';

            // Trigger a click event to start the download
            document.body.appendChild(a);
            a.click();

            // Clean up the created URL
            window.URL.revokeObjectURL(url);
        })
        .catch(() => {
            const maxRetries = options.maxRetries as number;
            if (options.retryOnTimeOut && currentAttempt < (maxRetries - 1)) {
                return request(endpoint, options, ++currentAttempt);
            }
            return new Error('Sorry we could not complete your request. Please try again.');
        })
}

export async function request(endpoint: string, options: CustomRequestInit, currentAttempt = 0): Promise<any> {
    const url = `${HOCOCO_API_BASE_URL[API_ENV]}${endpoint}`;
    return fetch(url, options)
        .then(async response => {
            if (response.status === HttpStatusCode.UNAUTHORIZED && options.logoutOn401 ) {
                await logout();
            }
            return response.json();
        })
        .then(data => data)
        .catch(() => {
            const maxRetries = options.maxRetries as number;
            if (options.retryOnTimeOut && currentAttempt < (maxRetries - 1)) {
                return request(endpoint, options, ++currentAttempt);
            }
            return new Error('Sorry we could not complete your request. Please try again.');
        })
}

export async function get(endpoint: string, options: CustomRequestInit = {}): Promise<any> {
    const requestOptions: CustomRequestInit = {
        ...DEFAULT_REQUEST_OPTIONS,
        ...options,
        method: 'GET'
    }
    return await request(endpoint, requestOptions);
}

export async function post(endpoint: string, options: CustomRequestInit = {}): Promise<any> {
    const requestOptions: CustomRequestInit = {
        ...DEFAULT_REQUEST_OPTIONS,
        ...options,
        method: 'POST'
    }

    return await request(endpoint, requestOptions)
}

export async function postBlob(endpoint: string, options: CustomRequestInit = {}): Promise<any> {
    const requestOptions: CustomRequestInit = {
        ...DEFAULT_REQUEST_OPTIONS,
        ...options,
        method: 'POST'
    }

    return await blobRequest(endpoint, requestOptions)
}

export async function getWithAuth(endpoint: string, options: CustomRequestInit = {}): Promise<any> {
    const accessToken = await getUserAccessToken();
    options.headers = new Headers({
        ...DEFAULT_REQUEST_OPTIONS.headers,
        'Authorization': `Bearer ${accessToken}`
    });
    return await get(endpoint, options);
}

export async function postWithAuth(endpoint: string, options: CustomRequestInit = {}): Promise<any> {
    const accessToken = await getUserAccessToken();
    options.headers = new Headers({
        ...DEFAULT_REQUEST_OPTIONS.headers,
        'Authorization': `Bearer ${accessToken}`
    });
    return await post(endpoint, options);
}

export async function postBlobWithAuth(endpoint: string, options: CustomRequestInit = {}): Promise<any> {
    const accessToken = await getUserAccessToken();
    options.headers = new Headers({
        ...DEFAULT_REQUEST_OPTIONS.headers,
        'Authorization': `Bearer ${accessToken}`,
    });
    return await postBlob(endpoint, options);
}