interface Prop {
    type?: AlertTypes
    text: string
}

export enum AlertTypes {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    SUCCESS = 'success',
    DANGER = 'danger',
    WARNING = 'warning',
    INFO = 'info',
    DARK = 'dark'
}

export function Alert({ type = AlertTypes.PRIMARY, text }: Prop) {
    return (
        <div className={`alert alert-${type}`} role="alert">
            {text}
        </div>
    )
}