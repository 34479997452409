import { login, saveUserTokenInfo } from '../../api/auth';
import { FormEvent, MutableRefObject, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { API_ENV, HOCOCO_ADMIN_BASE_URL } from '../../helpers/request';

export default function LoginContainer() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const passwordRef = useRef() as MutableRefObject<HTMLInputElement>;
    const ADMIN_BASE_URL = HOCOCO_ADMIN_BASE_URL[API_ENV];

    const handleLogin = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        let response = null;
        try {
            response = await login(email, password);
        } catch (e) {
            console.log(e);
        }
        if (response?.access_token) {
            saveUserTokenInfo(response);
            return window.location.reload();
        } else {
            setErrorMessage("The users credentials were incorrect");
        }
    }

    const handlePasswordVisibility = () => {
        if (passwordRef.current.type === 'text') {
            passwordRef.current.type = 'password'
        } else {
            passwordRef.current.type = 'text'
        }
    }

    return (
        <>
            <div className="authentication-wrapper authentication-cover">
                <div className="authentication-inner row m-0">
                    {/* /Left Text */}
                    <div className="d-none d-lg-flex col-lg-7 col-xl-8 align-items-center p-5">
                        <div className="w-100 d-flex justify-content-center">
                            <img src="https://i.imgur.com/4yBxSLI.png" className="img-fluid" alt="Login" width="700" />
                        </div>
                    </div>
                    {/* /Left Text */}
                    {/* Login */}
                    <div className="d-flex col-12 col-lg-5 col-xl-4 align-items-center authentication-bg p-sm-5 p-4">
                        <div className="w-px-400 mx-auto">
                            {/* Logo */}
                            <div className="app-brand mb-5">
                                <Link to="/" className="app-brand-link gap-2">
                                    <span className="app-brand-logo">
                                        <img src="https://global-uploads.webflow.com/620673c3bbc8c1b99ec8ff01/6206b65ff32a5f278059d71d_hococo_logo.svg" loading="lazy" alt="" />
                                    </span>
                                    <span className="app-brand-text text-body fw-bolder">Backoffice</span>
                                </Link>
                            </div>
                            {/* /Logo */}
                            <h4 className="mb-2">Welcome to Hococo Backoffice! 👋</h4>
                            <p className="mb-4">Please sign-in to your account</p>
                            <form id="formAuthentication" className="mb-3 fv-plugins-bootstrap5 fv-plugins-framework" onSubmit={(e) => handleLogin(e)} method="POST" noValidate>
                                <div className="mb-3 fv-plugins-icon-container">
                                    <label htmlFor="email" className="form-label">Email</label>
                                    <input type="text" onChange={e => setEmail(e.target.value)} className="form-control" id="email" name="email-username" placeholder="Enter your email or username" autoFocus />
                                </div>
                                <div className="mb-3 form-password-toggle fv-plugins-icon-container">
                                    <div className="d-flex justify-content-between">
                                        <label className="form-label" htmlFor="password">Password</label>
                                        <a href={`${ADMIN_BASE_URL}/user/reset-password`} target='_blank' rel='noreferrer'>
                                            <small>Forgot Password?</small>
                                        </a>
                                    </div>
                                    <div className="input-group input-group-merge has-validation">
                                        <input type="password" ref={passwordRef} onChange={e => setPassword(e.target.value)} id="password" className="form-control" name="password" placeholder="············" aria-describedby="password" />
                                        <span onClick={() => handlePasswordVisibility()} className="input-group-text cursor-pointer"><i className="bx bx-hide" /></span>
                                    </div>
                                    {errorMessage ?
                                        <div className="fv-plugins-message-container invalid-feedback" style={{ display: 'block' }}>
                                            <div data-field="password" data-validator="notEmpty">{errorMessage}</div>
                                        </div> : null
                                    }
                                </div>
                                <button className="btn btn-primary d-grid w-100">
                                    Sign in
                                </button>
                            </form>
                        </div>
                    </div>
                    {/* /Login */}
                </div>
            </div>

        </>
    )
}