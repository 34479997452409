import { post } from "../helpers/request";
import { getUserInfo } from "./user";
import { USER_INFO_KEY } from "../helpers/user";
import { getItem, removeItem, setItem } from "../helpers/storage";

const TOKEN_INFO_KEY = 'tokenInfo';

export interface UserTokenInfo {
    access_token: string,
    expires_in: number,
    refresh_token: string,
    token_type: string
}

export async function login(username: string, password: string): Promise<UserTokenInfo> {
    const body = JSON.stringify({
        username,
        password,
        grant_type: 'password',
        client_id: 2,
        client_secret: 'SVNR3D9ItGSQnTPTjCXt1jOO35XuKkSjB8zXrO3n',
        scope: ''
    });
    return await post(`/oauth/token`, { body, logoutOn401: false })
}

export async function saveUserTokenInfo(tokenInfo: UserTokenInfo): Promise<string> {
    return await setItem(TOKEN_INFO_KEY, JSON.stringify(tokenInfo))
}

export async function getUserTokenInfo(): Promise<string | null> {
    return await getItem(TOKEN_INFO_KEY);
}

export async function deleteUserTokenInfo(): Promise<void> {
    return await removeItem(TOKEN_INFO_KEY);
}

export async function getUserAccessToken(): Promise<string | null> {
    try {
        const tokenInfo: string = await getUserTokenInfo() as string;
        const userTokenInfo: UserTokenInfo = JSON.parse(tokenInfo) as UserTokenInfo;
        const { access_token } = userTokenInfo;
        return access_token;
    } catch (e) {
        console.log("Could not get the user token info", e);
        return null;
    }
}

export async function isUserAuthenticated(): Promise<boolean> {
    const accessToken = await getUserAccessToken();
    let isAuthenticated = false;
    if (accessToken) {
        try {
            const userInfo = await getUserInfo();
            if (userInfo) {
                isAuthenticated = true;
                await setItem(USER_INFO_KEY, JSON.stringify(userInfo));
            }
        } catch (e) {
            console.log("The user is not authenticated", e);
        }
    }
    return isAuthenticated;
}