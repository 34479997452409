interface Prop {
    color: SpinnerColor
}

export enum SpinnerColor {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    SUCCESS = 'success',
    DANGER = 'danger',
    WARNING = 'warning',
    INFO = 'info',
    LIGHT = 'light',
    DARK = 'dark',
}

export function Spinner({ color = SpinnerColor.PRIMARY }: Prop) {
    return (
        <div className={`spinner-border text-${color}`} role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
    )
}